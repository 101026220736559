<template>
  <div class="reportBase">
    <div class="search" style="display: block">
      <a-tree-select
        class="search-style"
        style="width: 300px"
        v-model="OrgIds"
        :tree-data="treeData"
        tree-checkable
        :maxTagCount="1"
        placeholder="请选择搜索范围"
        @change="change"
        :show-checked-strategy="SHOW_PARENT"
      />
      <!--<a-range-picker class="search-style" style="width: 200px" format="YYYY-MM" :mode="month" :placeholder="['开始时间','结束时间']"    @panelChange="handlePanelChange2" :value="value" @change="handleChangeTime" />-->
      <a-month-picker
        class="search-style"
        :value="timeValue1"
        @change="_startSignDate"
        placeholder="开始时间"
      />
      <a-month-picker
        class="search-style"
        :value="timeValue2"
        @change="_endSignDate"
        placeholder="结束时间"
      />
      <a-select
        mode="multiple"
        class="search-style"
        style="width: 240px"
        @change="handleChange"
        :maxTagCount="1"
        placeholder="请选择要显示的字段"
      >
        <a-select-option v-for="(item, index) in columns2" :key="index">
          {{ item.title }}
        </a-select-option>
      </a-select>
      <a-button class="search-style" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
    </div>

    <div style="width:1500px;overflow: auto" v-if="data">
      <a-table
        bordered
        :pagination="false"
        :columns="columns"
        :data-source="data"
        :scroll="scroll"
      >
      </a-table>
    </div>

    <div v-show="spinning" style="position:fixed;left:50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'reportBase',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      chartData: [
        { genre: 'Sports', sold: 275 },
        { genre: 'Strategy', sold: 115 },
        { genre: 'Action', sold: 120 },
        { genre: 'Shooter', sold: 350 },
        { genre: 'Fight', sold: 150 },
        { genre: 'Narration', sold: 23 },
        { genre: 'Race', sold: 223 },
        { genre: 'Advanture', sold: 57 },
        { genre: 'Puzzle', sold: 109 },
        { genre: 'Music', sold: 555 }
      ],
      columns2: [
        {
          title: '今日新单成交量',
          dataIndex: 'todayNewSignCount',
          key: 'todayNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '今日续单成交量',
          dataIndex: 'todayContinuedSignCount',
          key: 'todayContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周新单成交',
          dataIndex: 'thisWeekNewSignCount',
          key: 'thisWeekNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周续单成交',
          dataIndex: 'thisWeekContinuedSignCount',
          key: 'thisWeekContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周新单成交金额',
          dataIndex: 'thisWeekNewSignMoney',
          key: 'thisWeekNewSignMoney',
          align: 'center',
          width: 160
        },
        {
          title: '本周净课耗',
          dataIndex: 'thisWeekRealBrushMoney',
          key: 'thisWeekRealBrushMoney',
          align: 'center',
          width: 140
        },
        {
          title: '上周新单签单量',
          dataIndex: 'lastWeekNewSignCount',
          key: 'lastWeekNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上周续单签单量',
          dataIndex: 'lastWeekContinuedSignCount',
          key: 'lastWeekContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上周总成交金额',
          dataIndex: 'lastWeekTotalSignMoney',
          key: 'lastWeekTotalSignMoney',
          align: 'center',
          width: 140
        },

        {
          title: '上月新单成交量',
          dataIndex: 'lastMonthNewSignCount',
          key: 'lastMonthNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上月续单成交量',
          dataIndex: 'lastMonthContinuedSignCount',
          key: 'lastMonthContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上月总签单金额',
          dataIndex: 'lastMonthTotalSignMoney',
          key: 'lastMonthTotalSignMoney',
          align: 'center',
          width: 140
        },
        {
          title: '新单签单量',
          dataIndex: 'newSignNum',
          key: 'newSignNum',
          align: 'center',
          width: 140
        },
        {
          title: '续单签单量',
          dataIndex: 'continuedSignNum',
          key: 'continuedSignNum',
          align: 'center',
          width: 140
        },

        {
          title: '总签单金额',
          dataIndex: 'realTotalSignMoney',
          key: 'realTotalSignMoney',
          align: 'center',
          width: 140
        },
        {
          title: '退费人数',
          dataIndex: 'refundCount',
          key: 'refundCount',
          align: 'center',
          width: 140
        },
        {
          title: '退费金额',
          dataIndex: 'refundMoney',
          key: 'refundMoney',
          align: 'center',
          width: 140
        },
        {
          title: '净课耗',
          dataIndex: 'realBrushPrice',
          key: 'realBrushPrice',
          align: 'center',
          width: 140
        },
        {
          title: '历史成交人数',
          dataIndex: 'historySignCount',
          key: 'historySignCount',
          align: 'center',
          width: 140
        },
        {
          title: '总名单',
          dataIndex: 'totalStudentNum',
          key: 'totalStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '已结业人数',
          dataIndex: 'CompletedStudentNum',
          key: 'CompletedStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '已退费人数',
          dataIndex: 'refundStudentNum',
          key: 'refundStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '订金客户人数',
          dataIndex: 'depositStudentNum',
          key: 'depositStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '总续约人数',
          dataIndex: 'totalContinued',
          key: 'totalContinued',
          align: 'center',
          width: 140
        },
        {
          title: '总历史课耗',
          dataIndex: 'historyBrushMoney',
          key: 'historyBrushMoney',
          align: 'center',
          width: 180
        },
        {
          title: '总金额',
          dataIndex: 'totalMoney',
          key: 'totalMoney'
          // width:160,
          // fixed:'right',
        }
      ],
      columns: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 280,
          fixed: 'left'
        },
        {
          title: '今日新单成交量',
          dataIndex: 'todayNewSignCount',
          key: 'todayNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '今日续单成交量',
          dataIndex: 'todayContinuedSignCount',
          key: 'todayContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周新单成交',
          dataIndex: 'thisWeekNewSignCount',
          key: 'thisWeekNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周续单成交',
          dataIndex: 'thisWeekContinuedSignCount',
          key: 'thisWeekContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '本周新单成交金额',
          dataIndex: 'thisWeekNewSignMoney',
          key: 'thisWeekNewSignMoney',
          align: 'center',
          width: 160
        },
        {
          title: '本周净课耗',
          dataIndex: 'thisWeekRealBrushMoney',
          key: 'thisWeekRealBrushMoney',
          align: 'center',
          width: 140
        },
        {
          title: '上周新单签单量',
          dataIndex: 'lastWeekNewSignCount',
          key: 'lastWeekNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上周续单签单量',
          dataIndex: 'lastWeekContinuedSignCount',
          key: 'lastWeekContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上周总成交金额',
          dataIndex: 'lastWeekTotalSignMoney',
          key: 'lastWeekTotalSignMoney',
          align: 'center',
          width: 140
        },

        {
          title: '上月新单成交量',
          dataIndex: 'lastMonthNewSignCount',
          key: 'lastMonthNewSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上月续单成交量',
          dataIndex: 'lastMonthContinuedSignCount',
          key: 'lastMonthContinuedSignCount',
          align: 'center',
          width: 140
        },
        {
          title: '上月总签单金额',
          dataIndex: 'lastMonthTotalSignMoney',
          key: 'lastMonthTotalSignMoney',
          align: 'center',
          width: 140
        },
        {
          title: '新单签单量',
          dataIndex: 'newSignNum',
          key: 'newSignNum',
          align: 'center',
          width: 140
        },
        {
          title: '续单签单量',
          dataIndex: 'continuedSignNum',
          key: 'continuedSignNum',
          align: 'center',
          width: 140
        },

        {
          title: '总签单金额',
          dataIndex: 'realTotalSignMoney',
          key: 'realTotalSignMoney',
          align: 'center',
          width: 140
        },
        {
          title: '退费人数',
          dataIndex: 'refundCount',
          key: 'refundCount',
          align: 'center',
          width: 140
        },
        {
          title: '退费金额',
          dataIndex: 'refundMoney',
          key: 'refundMoney',
          align: 'center',
          width: 140
        },
        {
          title: '净课耗',
          dataIndex: 'realBrushPrice',
          key: 'realBrushPrice',
          align: 'center',
          width: 140
        },
        {
          title: '历史成交人数',
          dataIndex: 'historySignCount',
          key: 'historySignCount',
          align: 'center',
          width: 140
        },
        {
          title: '总名单',
          dataIndex: 'totalStudentNum',
          key: 'totalStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '已结业人数',
          dataIndex: 'CompletedStudentNum',
          key: 'CompletedStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '已退费人数',
          dataIndex: 'refundStudentNum',
          key: 'refundStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '订金客户人数',
          dataIndex: 'depositStudentNum',
          key: 'depositStudentNum',
          align: 'center',
          width: 140
        },
        {
          title: '总续约人数',
          dataIndex: 'totalContinued',
          key: 'totalContinued',
          align: 'center',
          width: 140
        },
        {
          title: '总历史课耗',
          dataIndex: 'historyBrushMoney',
          key: 'historyBrushMoney',
          align: 'center',
          width: 180
        },
        {
          title: '总金额',
          dataIndex: 'totalMoney',
          key: 'totalMoney'
          // width:160,
          // fixed:'right',
        }
      ],
      timeValue1: undefined,
      timeValue2: undefined,
      data: '',
      // columns,
      startSignDate: '',
      endSignDate: '',
      spinning: false,
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      treeData: [
        {
          title: 'Node1',
          value: '7520669f3b20008c6a6df71e9cebc2f9',
          key: '3',
          children: [
            {
              title: 'Child Node1',
              value: '7520669f3b20008c6a6df71e9cebc2f944',
              key: '4'
            }
          ]
        },
        {
          title: 'Node2',
          value: '6',
          key: '7',
          children: [
            {
              title: 'Child Node3',
              value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
              key: '8'
            },
            {
              title: 'Child Node4',
              value: '7520669f3b20008c6a6df71e9cebc2f9wee',
              key: '9'
            },
            {
              title: 'Child Node5',
              value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
              key: '1'
            }
          ]
        }
      ],
      OrgIds: [],
      OrgName: [],
      ParentId: [],
      SHOW_PARENT: 'SHOW_ALL',
      month: ['month', 'month'],
      value: [],
      scroll: { x: 4000, y: 500 }
    }
  },
  created () {
    this.loadData()
    this._treeData()
  },
  methods: {
    moment,
    _treeData () {
      let self = this
      this.$axios.get(
        1090,
        {
          UserId: self.userinfo.uid
        },
        res => {
          self.treeData = res.data
          console.log(res)
        }
      )
    },
    change (value, label, text) {
      if (text.checked) {
        this.ParentId.push(text.triggerValue)
      } else {
        if (this.ParentId.length > 0) {
          for (var i = 0; i < this.ParentId.length; i++) {
            if (text.triggerValue == this.ParentId[i]) {
              this.ParentId.splice(i, 1)
            }
          }
        }
      }

      this.OrgName = label
      this.OrgIds = value
    },
    loadData () {
      this.spinning = true
      let self = this
      this.$axios.post(
        1099,
        {
          StartDate: self.startSignDate,
          EndDate: self.endSignDate,
          OrgIds: JSON.stringify(self.OrgIds),
          UserId: self.userinfo.uid,
          OrgName: JSON.stringify(self.OrgName),
          ParentId: JSON.stringify(self.ParentId)
        },
        res => {
          self.spinning = false
          self.data = res.data
        }
      )
    },
    handleChange (value) {
      let arr = [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 280,
          fixed: 'left'
        }
      ]
      let width = 280
      for (var i = 0; i < this.columns2.length; i++) {
        if (value.indexOf(i) > -1) {
          arr.push(this.columns2[i])
          if (this.columns2[i].hasOwnProperty('width')) {
            width += parseInt(this.columns2[i].width)
          }
        }
      }
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].hasOwnProperty('width')) {
          if (arr[i].key == 'thisWeekNewSignMoney') {
            arr[i].width = 160
          } else if (arr[i].key == 'OrgName') {
            arr[i].width = 280
          } else {
            arr[i].width = 140
          }
          if (i == arr.length - 1) {
            arr[i].width = 0
          }
        }
      }
      if (width > 1000) {
        this.scroll.x = width + 300
      } else {
        this.scroll.x = 0
      }
      this.columns = arr
    },
    _startSignDate (value) {
      this.timeValue1 = value
      let date1 = new Date(value._d)
      this.startSignDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1)
    },
    _endSignDate (value) {
      this.timeValue2 = value
      let date2 = new Date(value._d)
      this.endSignDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1)
    },
    _search () {
      if (this.startSignDate) {
        if (!this.endSignDate) {
          this.$message.error('请选择结束时间')
          return false
        }
      }
      if (this.endSignDate) {
        if (!this.startSignDate) {
          this.$message.error('请选择开始时间')
          return false
        }
        let formatDate1 = new Date(this.startSignDate)
        let formatDate2 = new Date(this.endSignDate)
        if (formatDate1 > formatDate2) {
          this.$message.error('开始时间不能大于结束时间')
          return false
        }
      }

      this.loadData()
    },
    _clear () {
      this.OrgIds = []
      this.OrgName = []
      this.startSignDate = ''
      this.endSignDate = ''
      this.ParentId = []
      this.timeValue1 = undefined
      this.timeValue2 = undefined
      this.loadData()
    },
    handleChangeTime (value) {
      this.value = value
    },
    handlePanelChange2 (value, mode) {
      this.value = value
      this.month = [
        mode[0] === 'date' ? 'month' : mode[0],
        mode[1] === 'date' ? 'month' : mode[1]
      ]
      console.log(this.month)
      // let date1 = new Date(this.value[0]._d);
      // this.startSignDate = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1);
      // let date2 = new Date(this.value[1]._d);
      // this.endSignDate = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1);
    }
  }
}
</script>

<style scoped lang="less">
.reportBase {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}

.search {
  margin-bottom: 20px;
  .search-style {
    display: inline-block;
    width: 120px;
    margin-right: 15px;
  }
}
</style>
